@import
"src/main/scss/mixins/media-query",
"src/main/scss/mixins/state-indicator",
"src/main/scss/functions/find-color-invert";

.quote-outer {
  margin: var(--space-lg) 0;
}

.quote {
  position: relative;
  border-radius: 5px;
  overflow: hidden;
  padding: var(--space-md);
  //max-width: 65vw;
  margin: 0 auto;
  @include mq(lg) {
    // max-width: none;
    // padding-left: 260px;
    //
    // &:not(:last-child){
    //   margin-bottom: var(--space-xxl);
    // }
  }

  &__clickarea {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
    z-index: 2;
    outline: 0;
  }

  &__picture {
    width: 110px;
    height: 110px;
    border-radius: 50%;
    margin: 0 auto;
  }

  &__content {
    position: relative;
    font-style: italic;
    margin: 0;
    color: var(--color-text-meta);
    font-weight: var(--type-weight-base);
  }

  &__title {
    font-weight: var(--type-weight-meta);
    margin-bottom: var(--space-md);
  }

  &__media {
    position: relative;
    display: flex;
    margin-bottom: var(--space-md);
    @include mq(lg) {
      // position: absolute;
      // top: var(--space-md);
      // bottom: 0;
      // left: 0;
      // margin: 0;
      // align-items: flex-start;
    }

    // &:after {
    //   content: '\e900';
    //   display: block;
    //   font-family: var(--font-icon);
    //   font-size: var(--text-xxl);
    //   color: var(--color-primary);
    //   //position: absolute;
    //   //top: -10px;
    //   //left: -20px;
    //   transform: rotate(180deg);
    // }
  }

  &__media-image {
    max-width: 110px;
    @include mq(md) {
      max-width: 140px;
    }
  }

  &__author {
    font-style: normal;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: var(--space-md);
    @include mq(md) {
      justify-content: flex-start;
    }
  }

  &__author-image {
    min-width: 60px;
    min-height: 60px;
    padding-right: 15px;
    flex-shrink: 0;

    img {
      border-radius: 50%;
      width: 60px;
      height: 60px;
    }
  }

  &__author-wrapper {
    display: flex;
    flex-direction: column;
  }

  &__author-name {
    font-size: var(--text-sm);
    font-weight: var(--type-weight-meta);
    color: var(--color-text-meta);
  }

  &__author-position {
    font-size: var(--text-sm);
    font-weight: var(--type-weight-base);
    color: var(--color-text-meta);
  }

  &.is{
    &--sm {
      .quote {
        &__content {
          font-size: var(--text-base-size);
          letter-spacing: .042rem;

          &:before,
          &:after {
            font-size: 120%;
          }
        }

        &__author {
          margin-top: var(--space-xs);
        }

      }
    }
  }
}
